<template>
	<div class="modal" style="display:block">
		<div class="modal-content" v-bind:class="{ 'bravo' : hasError === false}">

            <template v-if="step == 1">
                <div class="indice">
                    <p>Déterminer l’IMC est important dans le choix de la thérapeutique <br>adaptée, la balance et la toise vous seront utiles pour le calculer.</p>
                    <img src="img/dossier/IMC-balance-toise.jpg" alt="Balance et toise">
                </div>
            </template>


            <template v-if="step == 2">
                <div :class="current.class">
                    <p v-if="hasError === null" v-html="current.libelle" class="blue">{{ current.libelle }}>{{ current.libelle }}</p>

                    <div v-if="hasError === true">
                        <p class="red"><strong><span v-html="current.messages.erreur"></span></strong></p>
                    </div>

                    <div v-if="hasError === false">
                        <h2>BRAVO !</h2>
                        <p><span v-html="current.messages.bravo"></span></p>
                    </div>

                    <div class="bloc-imc">
                        <img class="table" src="img/dossier/tableau-IMC.png" alt="Tableau IMC">
                        <img src="img/fleche-right.png">
                        <div class="form-imc">
                            <div class="imc">IMC</div>
                            <input ref="chiffre1" v-model="form.chiffre1" v-on:focus="goToEndOfText($event)" v-on:keyup="nextInput($event)" type="text" maxlength="1">
                            <input ref="chiffre2" v-model="form.chiffre2" v-on:focus="goToEndOfText($event)" v-on:keydown="testValue($event)" v-on:keyup="nextInput($event)" type="text" maxlength="1">
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <div class="modal-footer">
            <button v-if="step == 1" type="button" class="btn btn-blue align-right" @click="goNext()"><span>Suivant</span></button>

			<!-- BTN VALIDER -->
			<button v-if="step == 2 && hasError !== false" type="button" class="btn btn-blue align-center" @click="checkReponse()"><span>Valider</span></button>

			<!-- passer au quiz suivant ou close modal -->
			<button v-if="hasError === false" type="button" class="btn btn-blue align-right" @click="ecranSuivant()"><span>Suivant</span></button>
        </div>
    </div>
</template>


<script>
import jsonQuiz from '@/data/quiz.json'
import { EventBus } from '@/core/event-bus.js';

export default {
	name: 'QuizIMC',
    data() {
        return {
			quizList: jsonQuiz,
			currentQuestion: 2,
            step : 1,
            form : {
                chiffre1: null,
                chiffre2: null,
            },
            hasError: null
        }
    },
    computed : {
		current() {
			return this.quizList[this.currentQuestion]
		},
    },
	methods : {
		close() {
			this.$emit('close')
		},
        goNext(){
            this.step++;
        },
        testValue(e) { // pour revenir à l'input précédent si la valeur était vide avant d'appuyer sur backspace
            if (e.target.value.length === 0 && e.keyCode === 8) { // backspace
                if (e.target.previousElementSibling !== null) {
                    e.target.previousElementSibling.focus()
                }
            }
        },
        goToEndOfText(e) {
            let val = e.target.value
            e.target.value = val
        },
        nextInput(e) {
            if (e.keyCode === 8) { // backspace
                // on ne fait rien de plus, on a effacé le chiffre
            } else if (e.keyCode === 37) { // fleche gauche
                if (e.target.previousElementSibling !== null) {
                    e.target.previousElementSibling.focus()
                }
            } else {
                if (e.target.nextElementSibling !== null) {
                    e.target.nextElementSibling.focus()
                }
            }
        },
        checkReponse() {
            if (this.form.chiffre1 == 2 &&
                this.form.chiffre2 == 9
            ) {
                this.hasError = false
            } else {
                this.hasError = true

                // on reset les chiffres
                this.form.chiffre1 = ''
                this.form.chiffre2 = ''
            }
        },
        ecranSuivant() {
            // on enchaine sur le QCM imc
            let nextQuiz = this.quizList[this.current.enchaineQuizIndex];
            EventBus.$emit('changeQuiz', nextQuiz.type, nextQuiz.index);
        }
    }
}
</script>


<style scoped lang="scss">
    @import "@/assets/css/_variables.scss";

    .bravo {
        .bloc-imc {
            border: none;
            justify-content: center;

            img {
                display: none;
            }

            .form-imc {
                background: $green;
                margin: 4rem auto 0;
                height: 24rem;
                flex-basis: 30rem;
                min-width: 30rem;
                padding-top: 5rem;

                .imc {
                    background: $green;
                    color: $blue;
                    font-size: 6rem;
                }

                input {
                    border: none;
                    color: $blue;
                    font-family: 'Montserrat', sans-serif;
                    font-size: 13rem;
                    width: 8rem;
                }
            }
        }
    }

    .bloc-imc {
        border: 1px solid #333;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 1rem;

        .table {
            flex-basis: 55%;
            min-width: 55%;
            max-height: 42rem;
        }

        .form-imc {
            background: $navy;
            height: 16rem;
            flex-basis: 20rem;
            min-width: 20rem;
            margin-left: 4rem;
            padding-top: 3rem;
            position: relative;

            &:before {
                content: "";
                background: url("/img/fleche-right.png)") no-repeat 0 0;
                height: 4.5rem;
                width: 2.5rem;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }

            .imc {
                background: $navy;
                border: 2px solid white;
                color: white;
                font-size: 3.8rem;
                font-weight: 600;
                padding: 0 1rem;
                position: absolute;
                top: -3rem;
                left: 50%;
                transform: translateX(-50%);
            }

            input {
                background: none;
                border: none;
                border-bottom: 4px solid white;
                color: white;
                font-family: 'Montserrat', sans-serif;
                line-height: 1;
                font-size: 8rem;
                margin: 0 0.5rem;
                width: 5.5rem;
            }
        }
    }
</style>